import {
	Suspense,
	lazy
} from "react";
import {
	createBrowserRouter,
} from "react-router-dom";
import Loading from "../screens/loading";
import ProtectedRoute from "../components/protected-route";
const Book = lazy(() => import("../screens/book"));
const GastonomyActivities = lazy(() => import("../screens/gastonomy-activities"));
const App = lazy(() => import('../app'));
const Admin = lazy(() => import('../screens/admin'));
const AdminPanel = lazy(() => import('../screens/admin-panel'));
const Home = lazy(() => import('../screens/home'));
const Apartment = lazy(() => import('../screens/apartment'));
const AboutUs = lazy(() => import('../screens/about-us'));
const PrivacyCookiesPolicy = lazy(() => import('../screens/privacy-cookies-policy'));

export const globalRoutes = [
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<Home />
					</Suspense>
				)
			},
			{
				path: "admin",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<Admin />
					</Suspense>
				)
			},
			{
				path: "admin-panel",
				meta: { title: "" },
				element: (
					<ProtectedRoute token={sessionStorage.getItem('token')}>
						<Suspense fallback={<Loading />}>
							<AdminPanel />
						</Suspense>
					</ProtectedRoute>
				)
			},
			{
				path: "book",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<Book />
					</Suspense>
				)
			},
			{
				path: "gastronomy-activities",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<Home />
					</Suspense>
				)
			},
			{
				path: "gastronomy-activities-detail",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<GastonomyActivities />
					</Suspense>
				)
			},
			{
				path: "apartment",
				meta: { title: "" },
				children: [
					{
						path: "",
						meta: { title: "" },
						element: (
							<Suspense fallback={<Loading />}>
								<Apartment />
							</Suspense>
						)
					}
				]
			},
			{
				path: "about-us",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<AboutUs />
					</Suspense>
				)
			},
			{
				path: "privacy-policy",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<PrivacyCookiesPolicy />
					</Suspense>
				)
			},
			{
				path: "cookies-policy",
				meta: { title: "" },
				element: (
					<Suspense fallback={<Loading />}>
						<PrivacyCookiesPolicy />
					</Suspense>
				)
			}
        ]
    }
			
];

const router = createBrowserRouter(globalRoutes);

export default router;